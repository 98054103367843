<template>
	<article class="course__results--element">
		<header>
			<h3>{{ index + 1 }}. {{ header }}</h3>
			<aside class="course__results--wrapper">
				<v-btn @click="collapse(id)" class="result-button">{{
					buttonText
				}}</v-btn>
				<v-icon v-if="isCorrect === 1">mdi-check</v-icon>
				<v-icon v-if="isCorrect === 0">mdi-close</v-icon>
			</aside>
		</header>
		<div v-if="isOpen">{{ answer }}</div>
	</article>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "CourseResultsElement",
	props: {
		header: {
			type: String,
			require: true
		},
		answer: {
			type: String,
			require: true
		},
		id: {
			type: Number,
			require: true
		},
		index: {
			type: Number,
			require: true
		},
		isCorrect: {
			type: Number,
			require: true
		},
		opened: {
			type: Boolean,
			require: false
		}
	},
	data() {
		return {
			isOpen: this.opened
		};
	},
	watch: {
		opened: function (val) {
			this.isOpen = val;
		}
	},
	computed: {
		buttonText() {
			return this.isOpen === false ? "See answer" : "Hide answer";
		}
	},
	methods: {
		collapse() {
			this.isOpen === false
				? (this.isOpen = true)
				: (this.isOpen = false);
		}
	}
};
</script>

<style></style>
